import {
  Box,
  ModalCloseButton,
  HStack,
  Image,
  Text,
  Tooltip,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AssetType } from '../../utils/reports/match_utils'
import LinkCopy from './link_copy'
import DoppelLink from './doppel_link'
import type { Property } from 'csstype'

type MatchAndIndexSelector = {
  spoofMatches: any[]
  selectedMatchIndex: number
  setSelectedMatchIndex: (match_index: number) => void
}

type ImagePreviewSectionProps = {
  icon_url?: string
  title: string
  subtitle?: string
  imageUrl: string
  image_box_size?: { height: string; width: string }
  backup_image_url?: string
  is_flagged: boolean
  asset_type?: AssetType
  is_keyword?: boolean
  selectedMatchCoordinates?: { x: number; y: number }
  title_ref?: string
  setShouldShowDelisted?: (shouldShowDelisted: boolean) => void
  shouldShowMatchScreenshots?: boolean
  shouldShowDelisted?: boolean
  matchAndIndexSelector?: MatchAndIndexSelector
  objectFit?: Property.ObjectFit
}

const ImagePreviewSection = (props: ImagePreviewSectionProps) => {
  const {
    icon_url,
    title,
    subtitle,
    imageUrl,
    image_box_size = { width: 'sm', height: 'sm' },
    backup_image_url,
    is_flagged,
    asset_type,
    selectedMatchCoordinates,
    title_ref,
    objectFit = 'cover',
  } = props
  const [primaryImageURL, setPrimaryImageURL] = useState(imageUrl)
  const [imageLoading, setImageLoading] = useState(imageUrl ? false : true)

  const {
    isOpen: isImageFullscreenOpen,
    onOpen: onImageFullscreenOpen,
    onClose: onImageFullscreenClose,
  } = useDisclosure()

  useEffect(() => {
    if (imageUrl) {
      setPrimaryImageURL(imageUrl)
      setImageLoading(false)
    }
  }, [imageUrl])

  return (
    <>
      <HStack align="top">
        <VStack align="start">
          <HStack align="start" spacing={2} width="100%">
            <Box alignItems="start" display="flex" height="15px" paddingTop="5px">
              {icon_url && <Image height={'15px'} src={icon_url} width={'15px'} />}
            </Box>

            <Box flexGrow={1} marginX={1} textAlign="start">
              <HStack onClick={(e) => e.stopPropagation()} width="100%">
                {title_ref ? (
                  <DoppelLink href={title_ref} name={title} />
                ) : (
                  <Text fontSize={12}>{title}</Text>
                )}

                {!title_ref && <LinkCopy link={title} />}
              </HStack>

              <Text fontSize={12}>{subtitle}</Text>
            </Box>
          </HStack>

          <HStack>
            <VStack>
              <Box>
                {is_flagged && selectedMatchCoordinates && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <Image
                    boxShadow="l"
                    boxSize={'50px'}
                    marginLeft={_mapCoordinateToMargin(selectedMatchCoordinates.x)}
                    marginTop={_mapCoordinateToMargin(selectedMatchCoordinates.y)}
                    onClick={onImageFullscreenOpen}
                    position="absolute"
                    src={
                      'https://storage.googleapis.com/doppel_brand_assets/bounding_circle.png'
                    }
                    zIndex="10"
                  />
                )}

                <Tooltip
                  fontSize={'xs'}
                  height="35px"
                  isOpen={
                    asset_type == AssetType.Logo || asset_type == AssetType.Keywords
                  }
                  label={
                    asset_type == AssetType.Logo
                      ? 'Brand IP Detected'
                      : 'Keyword Detected'
                  }
                  marginTop={'-25px'}
                  paddingTop={'7px'}
                  placement="bottom-end"
                  roundedBottom={'lg'}
                  textAlign={'center'}
                  width="180px"
                >
                  <Image
                    alt={title}
                    boxShadow={'l'}
                    cursor={'pointer'}
                    display={imageLoading ? 'none' : 'initial'}
                    fallback={<Box boxSize={'sm'} />}
                    height={image_box_size.height}
                    objectFit={objectFit}
                    onClick={(e) => {
                      e.stopPropagation() // Prevents event bubbling
                      onImageFullscreenOpen()
                    }}
                    onError={() => {
                      setPrimaryImageURL(backup_image_url)
                    }}
                    onLoad={() => {
                      setImageLoading(false)
                    }}
                    opacity={
                      asset_type == AssetType.Logo || asset_type == AssetType.Keywords
                        ? 0.4
                        : 1
                    }
                    rounded={'md'}
                    src={primaryImageURL || imageUrl}
                    width={image_box_size.width}
                  />
                </Tooltip>
              </Box>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <Modal
        isOpen={isImageFullscreenOpen}
        onClose={onImageFullscreenClose}
        size="full"
      >
        <ModalOverlay />

        <ModalContent margin={0} minHeight="100vh" width="100vw">
          <ModalHeader>
            <ModalCloseButton onClick={onImageFullscreenClose} />
          </ModalHeader>

          <ModalBody alignItems="center" display="flex" justifyContent="center">
            <img
              alt="Full-size preview"
              src={primaryImageURL || imageUrl}
              style={{ maxHeight: '100vh', maxWidth: '100vw' }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

function _mapCoordinateToMargin(coordinate): number {
  return Math.max(coordinate * 180 - 25, 25)
}

export default ImagePreviewSection
