export const extractGCSDetails = (
  url: string,
): { bucketName: string; objectName: string } | null => {
  const regex = /https:\/\/storage\.googleapis\.com\/([^\\/]+)\/(.+)/
  const match = url.match(regex)

  if (!match || match.length < 3) {
    return null
  }

  const bucketName = match[1]
  const objectName = match[2]

  return { bucketName, objectName }
}
