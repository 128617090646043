import { withApollo } from '@apollo/react-hoc'
import {
  Button,
  FormControl,
  Heading,
  Stack,
  useToast,
  Tooltip,
  Textarea,
} from '@chakra-ui/react'
import { useState } from 'react'

import { EnforcementPlatform } from '../../utils/constants'

import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../../utils/style'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import { ThreatType } from '@/generated/enums'

type Props = {
  platformOptions: EnforcementPlatform[]
  onCompleted: any
  useDefaults?: boolean
  client: any
  returnToTableView: any
  threatType: ThreatType
}

const getResponseForEmailType = (threatType: ThreatType) => {
  // Hardcoded for now, eventally this should be configurable, also need to add customer name ideally
  switch (threatType) {
    case ThreatType.PAYLOAD:
      return 'Hi, this was malware, and a response has been pushed to the email security system.'
    case ThreatType.LINK:
    case ThreatType.RESPONSE:
      return 'Hi, this was phishing, and a response has been pushed to the email security system.'
    default:
      return ''
  }
}

const EmailCustomerResponseForm: React.FC<Props> = ({
  platformOptions,
  onCompleted: onCompleted,
  returnToTableView,
  threatType,
}) => {
  const toast = useToast()
  const [response, setResponse] = useState(getResponseForEmailType(threatType))

  const responseSection = (
    <FormControl>
      <Stack>
        <DoppelFormLabel
          formLabel="Customer Response"
          helperText="Response to send to customer via email"
        />

        <Textarea
          minH="12em"
          onChange={(e) => setResponse(e.target.value)}
          size="sm"
          value={response}
        />
      </Stack>
    </FormControl>
  )

  const isFormValid = response.length

  const renderSubmitButton = () => {
    return (
      <Tooltip
        label={
          isFormValid
            ? `Send response to customer`
            : `No response chosen to send to customer`
        }
        placement="top-start"
      >
        <span>
          <Button
            _hover={{ background: DOPPEL_INTERNAL_PURPLE }}
            bgColor={DOPPEL_CYBER_BLUE}
            color={DOPPEL_WHITE}
            isDisabled={!isFormValid}
            onClick={() => {
              //   const axiosRequests = enforcementFormInputs.map(
              //     (enforcementFormInput) => {
              //       handleEnforcementRequestSubmit(enforcementFormInput)
              //     },
              //   )
              //   Promise.all(axiosRequests).then(() => {
              //     onCompleted()
              //   })
              // TODO: add logic to send response once backend is ready
              toast({
                title: 'Success',
                description: '[NOT IMPLEMENTED] Response sent to customer',
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
              onCompleted()
            }}
            width="200px"
          >
            Submit
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <>
      <Heading as="h2" size="md">
        Response to customer and mark as malicious
      </Heading>

      <Heading as="h2" size="sm">
        Threat Type: {threatType}
      </Heading>

      {responseSection}

      {renderSubmitButton()}
    </>
  )
}
export default withApollo<Props>(EmailCustomerResponseForm)
