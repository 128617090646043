import { withApollo } from '@apollo/react-hoc'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
  Tooltip,
  HStack,
} from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import { FaPen } from 'react-icons/fa'
import {
  useUploadNewEnforcementRequestMutation,
  useSubmitFormAutoEnforcementRequestsMutation,
  useSubmitAutoEnforcementRequestsMutation,
  useGetEnforcementMetadataQuery,
  useGetPlatformsQuery,
  useGetEnforcementPlatformsQuery,
} from '../../generated/graphql'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import {
  DomainPlatform,
  ENFORCEMENT_STATUS,
  ENFORCEMENT_TYPE,
  EnforcementPlatform,
  SUPPORTED_API_ENFORCEMENT_OPTIONS,
  ProductType,
  SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS,
} from '../../utils/constants'
import {
  PRODUCT_TYPE_TO_ENFORCEMENT_TYPE,
  getDefaultEnforcementFormInputs,
  EnforcementMethod,
  getEnforcementMethod,
  isAutoEnforcementStagable,
  CRYPTO_STAGING_REQUIRED_PLATFORMS,
  getRootAndHost,
  AUTO_EMAIL_DISABLED_HOST_REGISTRAR_PLATFORMS,
  AUTO_EMAIL_DISABLED_ENFORCEMENT_PLATFORMS,
  isInHostOrRegistrar,
  renderRubricLink,
  renderUploadScreenshot,
  renderReasonsAutoStagingDisabled,
  renderReasonsStagingDisabled,
  stageEnforcementButtonDisabledReasonsTooltip,
  renderFlaggedUrl,
  STAGING_ONLY_PLATFORMS,
} from '../../utils/enforcement_utils'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  DOPPEL_BREACH_RED,
  DOPPEL_BUTTON_GREY,
} from '../../utils/style'
import AutomatedTakedownSection from './automated_takedown_section'
import {
  COPYRIGHTED_CONTENT_URL,
  COPYRIGHT_ORIGINAL_WORK_DESCRIPTION,
  COPYRIGHT_TYPE,
  EMAIL_BODY,
  SUBMISSION_EMAIL,
  TRADEMARK_ID,
} from './enforcement_user_input'
import { getOrgData } from '../../hooks/id_token_claims'
import DoppelPrimaryButton from '../shared/doppel_primary_button'
import SuggestedEnforcementsForm from './suggested_enforcements_form'
import DetailModal from '../shared_reports_table/detail_modal'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import { StagingDisabledReason, SubmissionSource } from '../../generated/enums'
import { IoMdAlert } from 'react-icons/io'
import { renderEnforcementRequests } from '@/utils/suggested_enforcements/display_enforcement_utils'

// Used by ops to report a case by creating an enforcement request

type Props = {
  platformOptions: EnforcementPlatform[]
  onCompleted: any
  useDefaults?: boolean
  client: any
  returnToTableView: any
}

export type EnforcementFormInput = {
  type: ENFORCEMENT_TYPE
  platform: EnforcementPlatform
  file: any
  userInput: any
  enforcementMethod: EnforcementMethod
  sameRootDomainAlertIds?: string[]
  suggestedEnforcementId?: string
}

const InternalEnforcementForm: React.FC<Props> = ({
  platformOptions,
  onCompleted: onCompleted,
  returnToTableView,
}) => {
  const toast = useToast()
  const overlayRef = useRef<HTMLInputElement>()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [submitAutoEnforcementRequests] = useSubmitAutoEnforcementRequestsMutation()
  const [enforcementFormInputs, setEnforcementFormInputs] = useState([])
  const [orgData] = getOrgData()

  const selectedSpoofReportProduct = selectedSpoofReport?.platform_subtype?.product
  const typeOptions = PRODUCT_TYPE_TO_ENFORCEMENT_TYPE[selectedSpoofReportProduct] || []

  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadMessage, setUploadMessage] = useState('')

  const [selectedEnforcementType, setSelectedEnforcementType] = useState(
    typeOptions.length == 1 ? typeOptions[0] : '',
  )

  const [selectedEnforcementPlatform, setSelectedEnforcementPlatform] = useState(
    platformOptions[0] || '',
  )

  const [customEnforcementPlatform, setCustomEnforcementPlatform] = useState('')

  const [uploadNewEnforcementRequestMutation] = useUploadNewEnforcementRequestMutation()

  // auto enforcement section
  const [isAutoAPIEnforcementEnabled, setIsAutoAPIEnforcementEnabled] = useState(false)
  const [submitFormAutoEnforcementRequests] =
    useSubmitFormAutoEnforcementRequestsMutation()
  const [enforcementUserInput, setEnforcementUserInput] = useState({})
  const [reasonsStagingDisabled, setReasonsStagingDisabled] = useState([])
  const [reasonsAutoStagingDisabled, setReasonsAutoStagingDisabled] = useState([])
  const [isAutoEmailEnforcementEnabled, setIsAutoEmailEnforcementEnabled] =
    useState(false)
  const [isManuallyCreatingEnforcement, setIsManuallyCreatingEnforcement] = useState(
    !SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS.includes(selectedSpoofReportProduct),
  )
  const [flaggedUrlEnforcementPlatformMatch, setFlaggedUrlEnforcementPlatformMatch] =
    useState('')
  const { data: enforcementMetadata } = useGetEnforcementMetadataQuery({
    variables: {
      org_id: orgData?.id,
    },
  })

  // only set submission email for domain reports
  const [submissionEmail, setSubmissionEmail] = useState(
    (selectedSpoofReportProduct === ProductType.DOMAIN &&
      selectedSpoofReport?.first_domain_data?.[0]?.full_url?.domain_data
        ?.contact_email) ||
      null,
  )

  const [showSuggestedEnforcementsForm, setShowSuggestedEnforcementsForm] =
    useState(false)

  const platformsWhereFlaggedUrl = {
    _and: [
      {
        platform_domains: { _contains: selectedSpoofReport?.flagged_url },
      },
    ],
  }

  const removeEnforcement = (pos) => {
    setEnforcementFormInputs((enforcementFormInputs) => {
      return enforcementFormInputs.filter((_, i) => i !== pos)
    })
  }

  const {
    data: platformsWhereFlaggedUrlRawData,
    refetch: platformsWhereFlaggedUrlRefetch,
  } = useGetPlatformsQuery({
    variables: {
      offset: 0,
      limit: 1,
      platformsWhere: platformsWhereFlaggedUrl,
      orderBy: [],
    },
  })

  const enforcementPlatformsWhereFlaggedUrl = {
    _and: [
      {
        match_strings: { _contains: selectedSpoofReport?.flagged_url },
      },
    ],
  }

  const { data: enforcementPlatformsWhereFlaggedUrlRawData } =
    useGetEnforcementPlatformsQuery({
      variables: {
        offset: 0,
        limit: 1,
        enforcementPlatformsWhere: enforcementPlatformsWhereFlaggedUrl,
        orderBy: [],
      },
    })

  const { root, host } = getRootAndHost(selectedSpoofReport?.flagged_url)

  const platformsWhereRootOrHost = {
    _and: [
      {
        _or: [
          {
            platform_domains: { _contains: root },
          },
          {
            platform_domains: { _contains: host },
          },
        ],
      },
    ],
  }

  const {
    data: platformsWhereRootOrHostRawData,
    refetch: platformsWhereRootOrHostRefetch,
  } = useGetPlatformsQuery({
    variables: {
      offset: 0,
      limit: 1,
      platformsWhere: platformsWhereRootOrHost,
      orderBy: [],
    },
  })

  const platformsWhereFlaggedUrlData = platformsWhereFlaggedUrlRawData?.platforms || []
  const enforcementPlatformsWhereFlaggedUrlData =
    enforcementPlatformsWhereFlaggedUrlRawData?.enforcement_platforms || []
  const platformsWhereRootOrHostData = platformsWhereRootOrHostRawData?.platforms || []

  const isAnyAutoEnforcementEnabled =
    isAutoAPIEnforcementEnabled || isAutoEmailEnforcementEnabled

  useEffect(() => {
    if (platformsWhereFlaggedUrlRawData?.platforms.length > 0) {
      setFlaggedUrlEnforcementPlatformMatch(
        platformsWhereFlaggedUrlRawData?.platforms?.[0]?.name,
      )
    } else if (enforcementPlatformsWhereFlaggedUrlData.length > 0) {
      setFlaggedUrlEnforcementPlatformMatch(
        enforcementPlatformsWhereFlaggedUrlData?.[0]?.name,
      )
    }
  }, [platformsWhereFlaggedUrlRawData, enforcementPlatformsWhereFlaggedUrlData])

  useEffect(() => {
    setIsAutoEmailEnforcementEnabled(false)
    setEnforcementUserInput({})

    const autoAPIEnabled = SUPPORTED_API_ENFORCEMENT_OPTIONS.some(
      (option) =>
        option[0] === selectedEnforcementPlatform &&
        option[1] === selectedEnforcementType,
    )
    setIsAutoAPIEnforcementEnabled(autoAPIEnabled)
  }, [selectedEnforcementPlatform, selectedEnforcementType])

  useEffect(() => {
    // only applies defaults if user hasnt created their own enforcements yet
    if (enforcementFormInputs.length || flaggedUrlEnforcementPlatformMatch) {
      return
    }
    platformsWhereFlaggedUrlRefetch()
    platformsWhereRootOrHostRefetch()
    const autoStagedEnforcements = getDefaultEnforcementFormInputs(
      selectedEnforcementType,
      selectedSpoofReportProduct,
      selectedSpoofReport,
      orgData?.customer_type,
      enforcementMetadata?.enforcement_metadata?.[0],
      platformsWhereFlaggedUrlData,
      platformsWhereRootOrHostData,
    )
    setEnforcementFormInputs(autoStagedEnforcements.enforcements)
    setReasonsAutoStagingDisabled(autoStagedEnforcements.disabledEnforcements)
  }, [selectedEnforcementType, selectedSpoofReportProduct])

  useEffect(() => {
    const reasons = []
    if (!selectedEnforcementType) {
      reasons.push(StagingDisabledReason.MISSING_ENFORCEMENT_TYPE)
    }
    if (!selectedEnforcementPlatform) {
      reasons.push(StagingDisabledReason.MISSING_PLATFORM)
    }
    if (!isAnyAutoEnforcementEnabled && !selectedFile) {
      reasons.push(StagingDisabledReason.MISSING_SCREENSHOT)
    }
    if (
      selectedEnforcementPlatform === DomainPlatform.CUSTOM &&
      !customEnforcementPlatform.length
    ) {
      reasons.push(StagingDisabledReason.MISSING_CUSTOM_PLATFORM_NAME)
    }
    if (
      !isAutoEnforcementStagable(
        selectedSpoofReport,
        selectedEnforcementPlatform,
        selectedEnforcementType,
      )
    ) {
      reasons.push(StagingDisabledReason.REQUEST_ALREADY_EXISTS)
    }
    setReasonsStagingDisabled(reasons)
  }, [
    selectedEnforcementPlatform,
    customEnforcementPlatform,
    isAnyAutoEnforcementEnabled,
    selectedFile,
    selectedEnforcementType,
    selectedSpoofReport,
  ])

  const isEnforcementValid = reasonsStagingDisabled.length === 0

  const addEnforcementRequest = (enforcementRequest) => {
    setEnforcementFormInputs([...enforcementFormInputs, enforcementRequest])
    setSelectedEnforcementType(typeOptions.length == 1 ? typeOptions[0] : '')
    setSelectedEnforcementPlatform(platformOptions[0] || '')
    setSelectedFile(null)
    setEnforcementUserInput({})
    setIsAutoEmailEnforcementEnabled(false)
  }

  async function handleEnforcementRequestSubmit(formInput: EnforcementFormInput) {
    if (formInput.enforcementMethod === EnforcementMethod.AUTO_FORM) {
      await submitFormAutoEnforcement(formInput)
    } else if (formInput.enforcementMethod === EnforcementMethod.AUTO_API) {
      await submitAPIAutoEnforcement(formInput)
    } else {
      await uploadScreenshotAndEnforcementRequest(formInput)
    }
  }

  async function submitAPIAutoEnforcement(formInput: EnforcementFormInput) {
    const spoofingReportID = selectedSpoofReport?.id
    const enforcementType = formInput.type
    const platform = formInput.platform
    const status = ENFORCEMENT_STATUS.STAGED
    const submittedAt = null
    const screenshotFileBase64Encoded = null
    const trademarkId = formInput.userInput?.[TRADEMARK_ID]
    const copyrightedContentUrl = formInput?.userInput?.[COPYRIGHTED_CONTENT_URL]
    const originalContentType = formInput?.userInput?.[COPYRIGHT_TYPE]
    const submissionEmail = formInput?.userInput?.[SUBMISSION_EMAIL]
    const emailBody = formInput?.userInput?.[EMAIL_BODY]
    const copyrightOriginalWorkDesc =
      formInput?.userInput?.[COPYRIGHT_ORIGINAL_WORK_DESCRIPTION]
    const sameRootDomainAlertIds = formInput.sameRootDomainAlertIds || []
    const suggestedEnforcementId = formInput.suggestedEnforcementId || null
    const submissionSource = submissionEmail
      ? SubmissionSource.AUTOMATED_EMAIL
      : SubmissionSource.API
    let enforcementRequestID = null

    await uploadNewEnforcementRequestMutation({
      variables: {
        enforcement_type: enforcementType,
        platform: platform,
        status: status,
        spoofing_report_id: spoofingReportID,
        submitted_at: submittedAt,
        screenshot_file: screenshotFileBase64Encoded,
        trademark_id: trademarkId,
        copyrighted_content_url: copyrightedContentUrl,
        original_content_type: originalContentType,
        submission_email: submissionEmail,
        body: emailBody,
        suggested_enforcement_id: suggestedEnforcementId,
        copyright_original_work_description: copyrightOriginalWorkDesc,
        submission_source: submissionSource,
      },
      onCompleted: (data) => {
        enforcementRequestID = data.upload_new_enforcement_request.id
      },
    })

    if (!enforcementRequestID) {
      toast({
        title: 'Failed to create Enforcement Request',
        status: 'error',
        isClosable: true,
      })
      return
    }

    await submitAutoEnforcementRequests({
      variables: {
        submit_auto_enforcement_requests_input: {
          enforcement_request_ids: [enforcementRequestID],
          same_root_domain_report_ids: sameRootDomainAlertIds,
        },
      },
      onCompleted: (data) => {
        let errors = ''
        for (const platform in data.submit_auto_enforcement_requests.results) {
          if (
            typeof data.submit_auto_enforcement_requests.results[platform] == 'string'
          ) {
            errors += `Failed to submit for ${platform}: ${data.submit_auto_enforcement_requests.results[platform]}\n`
          } else {
            for (const flagged_url in data.submit_auto_enforcement_requests.results[
              platform
            ]) {
              errors += `Failed to submit ${flagged_url} for ${platform}: ${data.submit_auto_enforcement_requests.results[platform][flagged_url]}\n`
            }
          }
        }

        if (errors.length > 0) {
          toast({
            title: 'Enforcement Request Failed to Submit',
            description: errors,
            isClosable: true,
            status: 'error',
          })
        } else {
          toast({
            title: 'Submitted Enforcement Request',
            status: 'success',
            isClosable: true,
          })
        }
      },
      onError: (error) => {
        toast({
          title: 'Failed to Submit Enforcement Requests',
          description: `Failed to submit enforcement requests`,
          status: 'error',
          isClosable: true,
        })
      },
    })
  }

  async function uploadScreenshotAndEnforcementRequest(
    formInput: EnforcementFormInput,
  ) {
    const shouldEnforcementRequestBeStaged =
      CRYPTO_STAGING_REQUIRED_PLATFORMS.includes(formInput.platform) ||
      STAGING_ONLY_PLATFORMS.includes(formInput.platform)

    const spoofingReportID = selectedSpoofReport?.id
    const enforcementType = formInput.type
    const platform = formInput.platform
    const status = shouldEnforcementRequestBeStaged
      ? ENFORCEMENT_STATUS.STAGED
      : ENFORCEMENT_STATUS.REPORTED
    const submittedAt = shouldEnforcementRequestBeStaged
      ? null
      : new Date().toUTCString()

    let screenshotFileBase64Encoded = null
    if (formInput.file) {
      // get screenshot file in base64
      const reader = new FileReader()
      // New Promise to handle asynchronous reading
      const fileAsBase64 = await new Promise<string | ArrayBuffer | null>(
        (resolve, reject) => {
          reader.readAsDataURL(formInput.file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        },
      )
      // Extract base64 data
      screenshotFileBase64Encoded = fileAsBase64?.toString().split(',')[1]
    }

    setUploadMessage(
      `Creating enforcement request for ${formInput.platform + '-' + formInput.type}`,
    )

    uploadNewEnforcementRequestMutation({
      variables: {
        enforcement_type: enforcementType,
        platform: platform,
        status: status,
        spoofing_report_id: spoofingReportID,
        submitted_at: submittedAt,
        screenshot_file: screenshotFileBase64Encoded,
        trademark_id: formInput.userInput?.trademarkId,
        copyrighted_content_url: formInput.userInput?.copyrightedContentUrl,
        original_content_type: formInput.userInput?.originalContentType,
        suggested_enforcement_id: formInput.suggestedEnforcementId || null,
      },
      onError: (error) => {
        setUploadMessage(
          `Upload failed for enforcement request ${
            formInput.platform + '-' + formInput.type
          }`,
        )
      },
      onCompleted: (data) => {
        const enforcement_request_id = data.upload_new_enforcement_request.id
        setSelectedFile(enforcement_request_id)
      },
    })
  }

  async function submitFormAutoEnforcement(formInput: EnforcementFormInput) {
    const spoofingReportID = selectedSpoofReport?.id
    const enforcementType = formInput.type
    const platform = formInput.platform
    const status = ENFORCEMENT_STATUS.STAGED
    const submittedAt = null
    const screenshotFileBase64Encoded = null
    const trademarkId = formInput.userInput?.[TRADEMARK_ID]
    const suggestedEnforcementId = formInput.suggestedEnforcementId || null
    const submissionSource = SubmissionSource.AUTOMATED_FORM
    let enforcementRequestID = null

    await uploadNewEnforcementRequestMutation({
      variables: {
        enforcement_type: enforcementType,
        platform: platform,
        status: status,
        spoofing_report_id: spoofingReportID,
        submitted_at: submittedAt,
        screenshot_file: screenshotFileBase64Encoded,
        trademark_id: trademarkId,
        suggested_enforcement_id: suggestedEnforcementId,
        submission_source: submissionSource,
      },
      onCompleted: (data) => {
        enforcementRequestID = data.upload_new_enforcement_request.id
      },
    })

    if (!enforcementRequestID) {
      toast({
        title: 'Failed to create Enforcement Request',
        status: 'error',
        isClosable: true,
      })
      return
    }

    await submitFormAutoEnforcementRequests({
      variables: {
        submit_form_auto_enforcement_requests_input: {
          enforcement_request_id: enforcementRequestID,
        },
      },
      onCompleted: (data) => {
        toast({
          title: 'Submitted Auto Form Enforcement Request',
          description: `Successfully submitted ${formInput?.type} enforcement request to ${formInput?.platform}`,
          status: 'success',
          isClosable: true,
        })
      },
      onError: (error) => {
        toast({
          title: 'Failed to Submit Auto Form Enforcement Request',
          description: `Failed to submit ${formInput?.type} enforcement request to ${formInput?.platform} with error: ${error.message}`,
          status: 'error',
          isClosable: true,
        })
      },
    })
  }

  const isFormValid = enforcementFormInputs.length

  const renderEnforcementTypeSelector = () => {
    return (
      <FormControl isRequired>
        <VStack alignItems="start">
          <FormLabel fontSize={13} htmlFor="enforcement_type">
            Type:
          </FormLabel>

          <Menu>
            <MenuButton as={Button} disabled={typeOptions.length <= 1} mb="4">
              <Flex>
                <Text fontSize="13" minWidth="100px" mr="2">
                  {selectedEnforcementType}
                </Text>

                {typeOptions.length > 1 && <ChevronDownIcon />}
              </Flex>
            </MenuButton>

            <MenuList maxHeight="280px" overflow="scroll">
              {typeOptions.map((option) => (
                <MenuItem
                  fontSize="13"
                  key={option}
                  onClick={() => setSelectedEnforcementType(option)}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </VStack>
      </FormControl>
    )
  }

  const renderEnforcementScreenshotUploader = () => {
    return (
      <VStack align="left">
        {selectedSpoofReportProduct === ProductType.DOMAIN &&
          Object.keys(
            isInHostOrRegistrar(
              selectedSpoofReport,
              AUTO_EMAIL_DISABLED_HOST_REGISTRAR_PLATFORMS,
              AUTO_EMAIL_DISABLED_HOST_REGISTRAR_PLATFORMS,
            ),
          ).length === 0 &&
          !AUTO_EMAIL_DISABLED_ENFORCEMENT_PLATFORMS.includes(
            selectedEnforcementPlatform as DomainPlatform,
          ) &&
          selectedEnforcementType !== ENFORCEMENT_TYPE.COPYRIGHT && (
            <DoppelPrimaryButton
              disabled={!selectedEnforcementPlatform || !selectedEnforcementType}
              maxWidth="210px"
              onClick={() => {
                setIsAutoEmailEnforcementEnabled(true)
                setEnforcementUserInput({
                  ...enforcementUserInput,
                  [SUBMISSION_EMAIL]: submissionEmail,
                })
              }}
            >
              Generate Auto Email
            </DoppelPrimaryButton>
          )}

        {renderUploadScreenshot(selectedFile, setSelectedFile)}
      </VStack>
    )
  }

  const renderEnforcementScreenshotToggler = () => {
    return (
      <Flex paddingTop={'35px'}>
        <Button
          bgColor={DOPPEL_INTERNAL_PURPLE}
          fontSize="12"
          h="40px"
          maxWidth="160px"
          minWidth="80px"
          onClick={() => {
            setIsAutoAPIEnforcementEnabled(false)
            setSelectedFile(null)
          }}
          textColor={DOPPEL_WHITE}
        >
          Manual Upload
        </Button>
      </Flex>
    )
  }

  const renderEnforcementPlatformSelector = () => {
    return (
      <FormControl isRequired>
        <VStack alignItems="start">
          <FormLabel fontSize={13} htmlFor="enforcement_platform">
            Platform:
          </FormLabel>

          <Menu>
            <MenuButton as={Button} disabled={platformOptions.length <= 1} mb="4">
              <Flex>
                <Text fontSize="13" minWidth="100px" mr="2">
                  {selectedEnforcementPlatform}
                </Text>

                {platformOptions.length > 1 && <ChevronDownIcon />}
              </Flex>
            </MenuButton>

            <MenuList maxHeight="280px" overflow="scroll">
              {platformOptions.map((option) => (
                <MenuItem
                  fontSize="13"
                  icon={
                    option === DomainPlatform.CUSTOM && (
                      <FaPen color={DOPPEL_CYBER_BLUE} />
                    )
                  }
                  key={option}
                  onClick={() => setSelectedEnforcementPlatform(option)}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          {selectedEnforcementPlatform === DomainPlatform.CUSTOM && (
            <Input
              fontSize={12}
              isInvalid={!customEnforcementPlatform.length}
              onChange={(e) => setCustomEnforcementPlatform(e.target.value)}
              placeholder="alibaba"
              width={150}
            />
          )}
        </VStack>
      </FormControl>
    )
  }

  const renderFlaggedUrlEnforcementPlatformMatchAlert = () => {
    return (
      <Stack>
        <HStack>
          <IconButton
            aria-label={'enforcement platform entity match alert'}
            colorScheme={'red'}
            icon={<IoMdAlert />}
            marginLeft={2}
            size="lg"
          />

          <Heading as="h4" color={DOPPEL_BREACH_RED} fontSize={18} size="sm">
            Entity matches exactly with known platform{' '}
            {flaggedUrlEnforcementPlatformMatch}. DO NOT SUBMIT ANY ENFORCEMENTS FOR
            THIS ALERT. Please create a new alert with the full path match URL and
            create enforcements on that new alert.
          </Heading>
        </HStack>

        {renderFlaggedUrl(selectedSpoofReport?.flagged_url)}
      </Stack>
    )
  }

  const renderStagedEnforcementRequests = () => {
    return (
      <>
        {reasonsAutoStagingDisabled?.length > 0 &&
          renderReasonsAutoStagingDisabled(reasonsAutoStagingDisabled)}

        {renderEnforcementRequests(
          enforcementFormInputs,
          (pos) => () => removeEnforcement(pos),
          selectedSpoofReportProduct,
        )}
      </>
    )
  }
  const renderEnforcementRequestStageButton = () => {
    return (
      <>
        {
          // only show reasons why staging is disabled if user already selected a platform and type
          !!selectedEnforcementType &&
            !!selectedEnforcementPlatform &&
            reasonsStagingDisabled?.length > 0 &&
            renderReasonsStagingDisabled(reasonsStagingDisabled)
        }

        {stageEnforcementButtonDisabledReasonsTooltip(
          <Button
            bgColor={DOPPEL_CYBER_BLUE}
            color={DOPPEL_WHITE}
            disabled={!isEnforcementValid}
            onClick={() => {
              addEnforcementRequest({
                platform: customEnforcementPlatform || selectedEnforcementPlatform,
                type: selectedEnforcementType,
                file: selectedFile,
                userInput: enforcementUserInput,
                enforcementMethod: isAnyAutoEnforcementEnabled
                  ? getEnforcementMethod(
                      isAutoEmailEnforcementEnabled,
                      selectedEnforcementPlatform,
                      selectedEnforcementType,
                    )
                  : EnforcementMethod.MANUAL,
              })
              setEnforcementUserInput({})
              setCustomEnforcementPlatform('')
            }}
            width="200px"
          >
            + Stage Enforcement
          </Button>,
          isEnforcementValid,
          reasonsStagingDisabled,
        )}
      </>
    )
  }

  const renderEnforcementRequestSubmitButton = () => {
    return (
      <Tooltip
        label={
          isFormValid
            ? `Will submit ${enforcementFormInputs.length} enforcements`
            : `No enforcements selected to stage`
        }
        placement="top-start"
      >
        <span>
          <Button
            _hover={{ background: DOPPEL_INTERNAL_PURPLE }}
            bgColor={DOPPEL_CYBER_BLUE}
            color={DOPPEL_WHITE}
            isDisabled={!isFormValid}
            onClick={() => {
              const axiosRequests = enforcementFormInputs.map(
                (enforcementFormInput) => {
                  handleEnforcementRequestSubmit(enforcementFormInput)
                },
              )
              Promise.all(axiosRequests).then(() => {
                onCompleted()
              })
            }}
            width="200px"
          >
            Submit
          </Button>
        </span>
      </Tooltip>
    )
  }

  const renderDomainsEnforcementsSubmissionOptions = () => {
    return (
      <Stack>
        <DoppelFormLabel
          formLabel='Please default to using "Generate Auto-Suggestions" for creating enforcement requests.'
          helperText='Use "Manually Generate Enforcement" if there is an error with auto-suggestions or you believe additional enforcement requests are necessary. To report TM enforcements for an organization that does not have any set trademarks, please Manually Generate Enforcements for now.'
        ></DoppelFormLabel>

        <HStack justify="space-between" spacing={4} width="100%">
          <Button
            bgColor={DOPPEL_CYBER_BLUE}
            flex="1"
            onClick={() => {
              if (!showSuggestedEnforcementsForm) {
                setShowSuggestedEnforcementsForm(true)
              }
            }}
          >
            Generate Auto-Suggestions
          </Button>

          <Button
            bgColor={DOPPEL_BUTTON_GREY}
            flex="1"
            onClick={() => {
              setIsManuallyCreatingEnforcement(!isManuallyCreatingEnforcement)
            }}
          >
            Manually Create Enforcement
          </Button>
        </HStack>
      </Stack>
    )
  }

  const renderManuallyCreatingEnforcementsSection = () => {
    return (
      <>
        {isManuallyCreatingEnforcement && (
          <>
            <SimpleGrid columns={3} spacing={2}>
              {renderEnforcementTypeSelector()}

              {renderEnforcementPlatformSelector()}

              {isAnyAutoEnforcementEnabled
                ? renderEnforcementScreenshotToggler()
                : renderEnforcementScreenshotUploader()}
            </SimpleGrid>

            {isAnyAutoEnforcementEnabled && (
              <AutomatedTakedownSection
                enforcementPlatform={selectedEnforcementPlatform}
                enforcementUserInput={enforcementUserInput}
                isAutoEmailEnforcementEnabled={isAutoEmailEnforcementEnabled}
                selectedEnforcementPlatform={selectedEnforcementPlatform}
                selectedEnforcementType={selectedEnforcementType}
                setEnforcementUserInput={setEnforcementUserInput}
                setReasonsStagingDisabled={setReasonsStagingDisabled}
                setSubmissionEmail={setSubmissionEmail}
                submissionEmail={submissionEmail}
              />
            )}

            {renderEnforcementRequestStageButton()}
          </>
        )}
      </>
    )
  }

  if (showSuggestedEnforcementsForm) {
    return (
      <DetailModal
        handleClickOutside={() => {
          setShowSuggestedEnforcementsForm(false)
          returnToTableView()
        }}
        overlayRef={overlayRef}
      >
        <SuggestedEnforcementsForm
          addSuggestedEnforcementsToForm={(enforcements) => {
            setShowSuggestedEnforcementsForm(false)
            setEnforcementFormInputs([...enforcementFormInputs, ...enforcements])
          }}
          closeSuggestedEnforcementsForm={() => setShowSuggestedEnforcementsForm(false)}
          currentStagedEnforcements={enforcementFormInputs}
          product={selectedSpoofReportProduct}
          returnToTableView={returnToTableView}
        />
      </DetailModal>
    )
  }

  return (
    <>
      <Heading as="h2" size="md">
        Create Enforcement
      </Heading>

      {flaggedUrlEnforcementPlatformMatch &&
        renderFlaggedUrlEnforcementPlatformMatchAlert()}

      {renderRubricLink(orgData)}

      {!flaggedUrlEnforcementPlatformMatch && (
        <>
          {SUGGESTED_ENFORCEMENT_ENABLED_PRODUCTS.includes(
            selectedSpoofReportProduct,
          ) && renderDomainsEnforcementsSubmissionOptions()}

          {renderManuallyCreatingEnforcementsSection()}

          <Heading as="h2" size="md">
            Staged Enforcement(s)
          </Heading>

          {renderStagedEnforcementRequests()}

          {uploadMessage ? (
            <Text>{uploadMessage}</Text>
          ) : (
            renderEnforcementRequestSubmitButton()
          )}
        </>
      )}
    </>
  )
}

export default withApollo<Props>(InternalEnforcementForm)
