import {
  AppsFormData,
  DisabledSuggestedEnforcement,
  DomainsFormData,
  SocialMediaFormData,
  SuggestedEnforcement,
} from './suggested_enforcement_types'
import { DISABLED_REASON_TO_DISABLED_INFO } from './suggested_enforcement_constants'
import {
  EnforcementType,
  GenerateSuggestedEnforcementsMode,
  SuggestedEnforcementDisabledReason,
} from '@/generated/enums'
import { GenerateSuggestedEnforcementsInput } from './suggested_enforcement_types'
import { updateUserInputWithSubmissionEmail } from './update_suggested_enforcement_utils'

export const fillInMissingDisabledEnforcementFields = (
  disabledSuggestedEnforcement: DisabledSuggestedEnforcement,
): DisabledSuggestedEnforcement => {
  const { requiredAction, shouldDisplayInitially } =
    DISABLED_REASON_TO_DISABLED_INFO[
      disabledSuggestedEnforcement.reason as SuggestedEnforcementDisabledReason
    ]
  return {
    platforms: disabledSuggestedEnforcement.platforms,
    enforcement_types: disabledSuggestedEnforcement.enforcement_types,
    reason: disabledSuggestedEnforcement.reason,
    source: disabledSuggestedEnforcement.source,
    params: disabledSuggestedEnforcement.params,
    platform_type: disabledSuggestedEnforcement.platform_type,
    requiredAction: requiredAction,
    shouldDisplayInitially: shouldDisplayInitially,
  }
}

export const fillInMissingSuggestedEnforcementFields = (
  suggestedEnforcement: SuggestedEnforcement,
): SuggestedEnforcement => {
  const selectedMethod = suggestedEnforcement.allowed_enforcement_methods[0]
  const selectedMethodData = selectedMethod.method_data
  const updatedEnforcement = {
    ...suggestedEnforcement,
    selected_platform_name: suggestedEnforcement.suggested_platform_name,
    selected_method: selectedMethod,
    selected_platform_selection_method:
      suggestedEnforcement.suggested_platform_selection_method,
  }

  return updateUserInputWithSubmissionEmail(updatedEnforcement, selectedMethodData)
}

export const createGenerateSuggestedEnforcementsSingleFormInput = (
  productFormData: DomainsFormData | AppsFormData | SocialMediaFormData,
  spoof_report_id: string,
  product_type: string,
  staged_enforcements,
): GenerateSuggestedEnforcementsInput => {
  return {
    product_type: product_type,
    spoof_report_ids: [spoof_report_id],
    product_form_data: productFormData,
    mode: GenerateSuggestedEnforcementsMode.SUGGESTED_ENFORCEMENT_FORMS,
    staged_enforcements: staged_enforcements,
  }
}

export const createGenerateSuggestedEnforcementsUserEnforcementInput = (
  enforcement_type: EnforcementType,
  product_type: string,
  spoof_report_id: string,
  staged_enforcements,
  enforcement_platform_type?,
  trademark_id?,
  content_type?,
  url_type?,
): GenerateSuggestedEnforcementsInput => {
  return {
    product_type: product_type,
    spoof_report_ids: [spoof_report_id],
    mode: GenerateSuggestedEnforcementsMode.SINGLE_ENFORCEMENT,
    individual_enforcement_data: {
      enforcement_type: enforcement_type,
      enforcement_platform_type: enforcement_platform_type,
      trademark_id: trademark_id,
      content_type: content_type,
      url_type: url_type,
    },
    staged_enforcements: staged_enforcements,
  }
}

export const createEnforcementMethodsFromEnforcementCategories = (
  enforcement_categories,
) => {
  return enforcement_categories.map((enforcement_category) => {
    return {
      method_type: enforcement_category.enforcement_method_type,
      method_data: enforcement_category.enforcement_method_data,
    }
  })
}
