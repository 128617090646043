import { ProductType } from '@/generated/enums'
import { useGetSpoofTemplateInputLazyQuery } from '@/generated/graphql'
import { REPORTING_AGENT } from '@/utils/constants'
import { useEffect } from 'react'

export function useFetchTemplateInput(
  selectedSpoofReport,
  selectedEnforcementPlatform,
) {
  const [getSpoofTemplateInput, { data: templateInput, loading, error }] =
    useGetSpoofTemplateInputLazyQuery()

  useEffect(() => {
    if (!selectedSpoofReport || !selectedEnforcementPlatform) return

    const protectedAssetsWhere = {
      _and: [
        {
          is_primary: { _eq: true },
        },
        selectedSpoofReport?.platform_subtype?.product === ProductType.DOMAIN
          ? {
              platform: { product: { _eq: ProductType.DOMAIN } },
            }
          : {
              platform_name: { _eq: selectedEnforcementPlatform },
            },
      ],
    }

    // Execute the lazy query
    getSpoofTemplateInput({
      variables: {
        entityId: selectedSpoofReport.original_entity_id,
        protectedAssetsWhere,
      },
    })
  }, [selectedSpoofReport, selectedEnforcementPlatform])

  return { templateInput, loading, error }
}

function useTemplateInfoInputs({
  templateInput,
  setTemplateInfoInputs,
  selectedEnforcementPlatform,
  submissionEmail,
  selectedSpoofReport,
  protectedAssets = null,
}) {
  useEffect(() => {
    const setTemplateInputs = async () => {
      let enforcementMetadata =
        templateInput?.spoofing_entities[0]?.organization?.enforcement_metadata || {}

      // Removing __typename from enforcementMetadata
      if (enforcementMetadata && '__typename' in enforcementMetadata) {
        const { ['__typename']: _, ...sanitizedMetadata } = enforcementMetadata
        enforcementMetadata = sanitizedMetadata
      }

      // Set moreInfoInputs
      setTemplateInfoInputs((prevState) => ({
        ...prevState,
        slug:
          selectedSpoofReport?.spoof_matches?.[0]?.social_media_data?.slug ||
          selectedSpoofReport?.social_media_data?.slug ||
          null,
        ...enforcementMetadata,
        option: '',
        platform: selectedEnforcementPlatform,
        flagged_url: selectedSpoofReport?.flagged_url,
        matched_urls: selectedSpoofReport?.spoof_matches
          .map((match) => match?.full_url?.url)
          .join(', '),
        reporting_agent: REPORTING_AGENT,
        screenshot_urls: selectedSpoofReport?.spoof_matches
          .map((match) => match?.full_url?.screenshot_url)
          .join(', '),
        ip_address: selectedSpoofReport?.domain_data?.ip_address
          ? 'Domain IP Address: ' + selectedSpoofReport?.domain_data?.ip_address
          : '',
        entity_url:
          templateInput?.spoofing_entities?.[0]?.protected_assets?.[0]?.full_url?.url,
        submission_email: submissionEmail,
        protected_assets: protectedAssets ? protectedAssets : [],
      }))
    }

    setTemplateInputs()
  }, [
    selectedSpoofReport,
    templateInput,
    submissionEmail,
    selectedEnforcementPlatform,
    protectedAssets,
  ])
}

export default useTemplateInfoInputs
